<template>
    <div class="headerWrap">
        <p style="display: flex">
            <img src="@/assets/img/common/logo.png">
            <span class="headerLogo">ParamIOT物联网中心</span>
        </p>
        <div class="headerRight">
            <ul class="headerMenu">
                <li :style="path === '/project-manage' ? 'color:#3F8AE0' : ''" @click="changeRoute('/project-manage')">
                    项目管理
                </li>
                <li :style="path === '/order/index' ? 'color:#3F8AE0' : ''" @click="changeRoute('/order/index')">
                    我的工单
                </li>
                <li :style="path === '/file' ? 'color:#3F8AE0' : ''" @click="changeRoute('/file')">
                    开发文档
                </li>
            </ul>
            <div class="headerNotice">
                <a-dropdown placement="topRight">
                    <a-badge count="3"
                             :offset="[6, 0]"
                             :number-style="{
                                 backgroundColor: '#FF4D4F',
                                 boxShadow: 'none',
                                 minWidth: '31px'
                             }">
                        <a-icon type="mail" style="font-size: 18px" />
                    </a-badge>
                    <a-menu slot="overlay">
                        <a-menu-item>
                            <p class="dropTip">
                                平台通知：2021-06-08 12:00
                            </p>
                            <p>系统更新了XXX版本</p>
                        </a-menu-item>
                        <a-menu-item>
                            <p class="dropTip">
                                平台通知：2021-06-06  12:00
                            </p>
                            <p>平台新增XX协议支持，点击查看</p>
                        </a-menu-item>
                        <a-menu-item>
                            <p class="dropTip">
                                项目通知：2021-06-07 00:00
                            </p>
                            <p>平台新增XX协议支持，点击查看</p>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
            <div>
                <a-dropdown>
                    <img src="@/assets/img/common/user.png">
                    <a-menu slot="overlay">
                        <a-menu-item>
                            <span @click="gotoAccountCenter">账号信息</span>
                        </a-menu-item>
                        <a-menu-item>
                            <span @click="outLogin">退出登录</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
// import store from '@/store'
import { doLogout } from "sso-login-logic"
export default {
    name: "Header",
    data() {
        return {
            path: this.$route.path
        }
    },
    methods: {
        changeRoute(url) {
            if (!(this.$route.path === url)) {
                this.$router.push(url)
            }
        },
        // 进入账号信息页
        gotoAccountCenter() {
            this.$router.push('/account/info')
        },
        // 退出登录
        outLogin() {
            // store.dispatch('login/resetToken').then(() => {
            //     return
            // }).catch(() => {
            //     console.log('token remove error!')
            // })
            // this.$router.push(`/?redirect=${this.$route.fullPath}`)
            doLogout(process.env.VUE_APP_LOGINBASE_URL)
        }
    }
}
</script>

<style lang="scss" scoped>
    p, ul {
        margin-bottom: 0;
    }
    .headerWrap {
        padding: 0 24px 0 16px;
        height: 64px;
        background: #272636;
        box-shadow: 0 2px 8px #F0F1F2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .headerLogo {
            margin-left: 16px;
            font-size: 22px;
            font-weight: bold;
        }
    }
    .headerRight {
        display: flex;
        align-items: center;
    }
    .headerMenu {
        display: flex;
        li {
            margin-left: 40px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;
        }
        li:hover {
            color: #3F8AE0;
        }
    }
    .headerNotice {
        margin: 0 30px 0 40px;
    }
    .dropTip {
        color: #999;
    }
</style>
