var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headerWrap" }, [
    _vm._m(0),
    _c("div", { staticClass: "headerRight" }, [
      _c("ul", { staticClass: "headerMenu" }, [
        _c(
          "li",
          {
            style: _vm.path === "/project-manage" ? "color:#3F8AE0" : "",
            on: {
              click: function ($event) {
                return _vm.changeRoute("/project-manage")
              },
            },
          },
          [_vm._v(" 项目管理 ")]
        ),
        _c(
          "li",
          {
            style: _vm.path === "/order/index" ? "color:#3F8AE0" : "",
            on: {
              click: function ($event) {
                return _vm.changeRoute("/order/index")
              },
            },
          },
          [_vm._v(" 我的工单 ")]
        ),
        _c(
          "li",
          {
            style: _vm.path === "/file" ? "color:#3F8AE0" : "",
            on: {
              click: function ($event) {
                return _vm.changeRoute("/file")
              },
            },
          },
          [_vm._v(" 开发文档 ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "headerNotice" },
        [
          _c(
            "a-dropdown",
            { attrs: { placement: "topRight" } },
            [
              _c(
                "a-badge",
                {
                  attrs: {
                    count: "3",
                    offset: [6, 0],
                    "number-style": {
                      backgroundColor: "#FF4D4F",
                      boxShadow: "none",
                      minWidth: "31px",
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticStyle: { "font-size": "18px" },
                    attrs: { type: "mail" },
                  }),
                ],
                1
              ),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c("a-menu-item", [
                    _c("p", { staticClass: "dropTip" }, [
                      _vm._v(" 平台通知：2021-06-08 12:00 "),
                    ]),
                    _c("p", [_vm._v("系统更新了XXX版本")]),
                  ]),
                  _c("a-menu-item", [
                    _c("p", { staticClass: "dropTip" }, [
                      _vm._v(" 平台通知：2021-06-06 12:00 "),
                    ]),
                    _c("p", [_vm._v("平台新增XX协议支持，点击查看")]),
                  ]),
                  _c("a-menu-item", [
                    _c("p", { staticClass: "dropTip" }, [
                      _vm._v(" 项目通知：2021-06-07 00:00 "),
                    ]),
                    _c("p", [_vm._v("平台新增XX协议支持，点击查看")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a-dropdown",
            [
              _c("img", {
                attrs: { src: require("@/assets/img/common/user.png") },
              }),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c("a-menu-item", [
                    _c("span", { on: { click: _vm.gotoAccountCenter } }, [
                      _vm._v("账号信息"),
                    ]),
                  ]),
                  _c("a-menu-item", [
                    _c("span", { on: { click: _vm.outLogin } }, [
                      _vm._v("退出登录"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { display: "flex" } }, [
      _c("img", { attrs: { src: require("@/assets/img/common/logo.png") } }),
      _c("span", { staticClass: "headerLogo" }, [_vm._v("ParamIOT物联网中心")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }